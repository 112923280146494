import React, {useEffect} from 'react'
import classNames from 'classnames'
import ArrowLeftSmall from 'wix-ui-icons-common/ArrowLeftSmall'
import ArrowRightSmall from 'wix-ui-icons-common/ArrowRightSmall'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {TextButton} from 'wix-ui-tpa/TextButton'
import {hookToAttributeSelector, focusElement, addCircularTabFocus} from '@wix/wix-events-commons-statics'
import {classes} from './slide-in-modal.st.css'
import {SlideInModalProps} from './index'

export const ANIMATION_SPEED = 200 // should match animation-speed in st.css

export const SlideInModal: React.FC<SlideInModalProps> = ({
  id,
  className,
  children,
  show,
  backButtonText,
  fromBottom,
  animations = true,
  immediate = false,
  focus,
  circularFocus,
  onBackClick,
}) => {
  const {isRTL} = useEnvironment()
  const {t} = useTranslation()
  const hidden = !show
  const backButtonDH = `${id}-${DH.BACK}`

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        focusElement({selector: hookToAttributeSelector(focus || backButtonDH), canFocus: !focus})
        addCircularTabFocus(
          hookToAttributeSelector(circularFocus || backButtonDH),
          hookToAttributeSelector(DH.CLOSE_BUTTON),
        )
      }, ANIMATION_SPEED)
    }
  }, [show, focus])

  return (
    <div
      className={classNames(classes.container, className, {
        [classes.fromBottom]: fromBottom,
        [classes.show]: show,
        [classes.hide]: hidden,
        [classes.animations]: animations,
        [classes.immediate]: immediate,
      })}
      role="dialog"
      aria-hidden={hidden}
    >
      {onBackClick ? (
        <div className={classes.header}>
          <TextButton
            prefixIcon={isRTL ? <ArrowRightSmall /> : <ArrowLeftSmall />}
            className={classes.backButton}
            onClick={onBackClick}
            data-hook={backButtonDH}
          >
            {backButtonText ?? t('back')}
          </TextButton>
        </div>
      ) : null}
      {children}
    </div>
  )
}
