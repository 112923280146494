import React from 'react'
import {Text} from 'wix-ui-tpa/Text'
import {useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {LabelWithValue} from '../../../../common/label-with-value'
import {getPlaceInfoItems} from '../../../../../selectors/seating'
import {classes} from './ticket-info-texts.st.css'
import {TicketInfoTextsProps} from './index'

export const TicketInfoTexts = ({place, inPopover}: TicketInfoTextsProps) => {
  const {t} = useTranslation()
  const {
    placeInfo,
    ticketInfo: {ticketName, ticketPrice, fees},
  } = getPlaceInfoItems(place, t)

  return (
    <Text tagName="p" className={classes.container}>
      <Text tagName="p" className={classNames(classes.infoContainer, {[classes.paddingRight]: !inPopover})}>
        {placeInfo.map(({label, value}, index) => (
          <LabelWithValue key={index} labelClassName={classes.label} label={label} value={value} />
        ))}
      </Text>
      <Text tagName="p" className={classes.ticketInfo}>
        <Text className={classes.ticketName}>{ticketName}</Text>
        <Text className={classes.ticketPrice}>{ticketPrice}</Text>
        <Text className={classes.smallText}>{fees}</Text>
      </Text>
    </Text>
  )
}
